@font-face {
  font-family: 'ikbs';
  src: url('../font/ikbs.eot?97279507');
  src: url('../font/ikbs.eot?97279507#iefix') format('embedded-opentype'),
       url('../font/ikbs.woff2?97279507') format('woff2'),
       url('../font/ikbs.woff?97279507') format('woff'),
       url('../font/ikbs.ttf?97279507') format('truetype'),
       url('../font/ikbs.svg?97279507#ikbs') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ikbs';
    src: url('../font/ikbs.svg?97279507#ikbs') format('svg');
  }
}
*/
[class^="ikbs-"]:before, [class*=" ikbs-"]:before {
  font-family: "ikbs";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ikbs-advanced_hover:before { content: '\e800'; } /* '' */
.ikbs-arrow_right:before { content: '\e801'; } /* '' */
.ikbs-arrow_up:before { content: '\e802'; } /* '' */
.ikbs-c:before { content: '\e803'; } /* '' */
.ikbs-back_step:before { content: '\e804'; } /* '' */
.ikbs-bank:before { content: '\e805'; } /* '' */
.ikbs-bluecross:before { content: '\e806'; } /* '' */
.ikbs-advanced:before { content: '\e807'; } /* '' */
.ikbs-blue_shield:before { content: '\e808'; } /* '' */
.ikbs-arrow_down:before { content: '\e809'; } /* '' */
.ikbs-advertising:before { content: '\e80a'; } /* '' */
.ikbs-check:before { content: '\e80b'; } /* '' */
.ikbs-close:before { content: '\e80c'; } /* '' */
.ikbs-colapse:before { content: '\e80d'; } /* '' */
.ikbs-dashboad:before { content: '\e80e'; } /* '' */
.ikbs-deposit:before { content: '\e80f'; } /* '' */
.ikbs-dashicons_randomize:before { content: '\e810'; } /* '' */
.ikbs-descending:before { content: '\e811'; } /* '' */
.ikbs-blueemail:before { content: '\e812'; } /* '' */
.ikbs-blureuro:before { content: '\e813'; } /* '' */
.ikbs-calendar:before { content: '\e814'; } /* '' */
.ikbs-chart:before { content: '\e815'; } /* '' */
.ikbs-bxs_hide:before { content: '\e816'; } /* '' */
.ikbs-exit:before { content: '\e817'; } /* '' */
.ikbs-exit_outlined:before { content: '\e818'; } /* '' */
.ikbs-expand:before { content: '\e819'; } /* '' */
.ikbs-expand_d:before { content: '\e81a'; } /* '' */
.ikbs-export:before { content: '\e81b'; } /* '' */
.ikbs-file:before { content: '\e81c'; } /* '' */
.ikbs-filter:before { content: '\e81d'; } /* '' */
.ikbs-finger_print:before { content: '\e81e'; } /* '' */
.ikbs-document:before { content: '\e81f'; } /* '' */
.ikbs-drag-indicator:before { content: '\e820'; } /* '' */
.ikbs-email:before { content: '\e821'; } /* '' */
.ikbs-tx_bell:before { content: '\e822'; } /* '' */
.ikbs-minus:before { content: '\e823'; } /* '' */
.ikbs-next_step:before { content: '\e824'; } /* '' */
.ikbs-pencil:before { content: '\e825'; } /* '' */
.ikbs-pin:before { content: '\e826'; } /* '' */
.ikbs-plus:before { content: '\e827'; } /* '' */
.ikbs-presentation:before { content: '\e828'; } /* '' */
.ikbs-randomsort:before { content: '\e829'; } /* '' */
.ikbs-grid:before { content: '\e82a'; } /* '' */
.ikbs-info:before { content: '\e82b'; } /* '' */
.ikbs-information:before { content: '\e82c'; } /* '' */
.ikbs-ip:before { content: '\e82d'; } /* '' */
.ikbs-key:before { content: '\e82e'; } /* '' */
.ikbs-left_stop:before { content: '\e82f'; } /* '' */
.ikbs-lightning:before { content: '\e830'; } /* '' */
.ikbs-list:before { content: '\e831'; } /* '' */
.ikbs-login:before { content: '\e832'; } /* '' */
.ikbs-redexit:before { content: '\e833'; } /* '' */
.ikbs-refresh:before { content: '\e834'; } /* '' */
.ikbs-refresh_hover:before { content: '\e835'; } /* '' */
.ikbs-safe:before { content: '\e836'; } /* '' */
.ikbs-save:before { content: '\e837'; } /* '' */
.ikbs-search:before { content: '\e838'; } /* '' */
.ikbs-send_email:before { content: '\e839'; } /* '' */
.ikbs-send_message:before { content: '\e83a'; } /* '' */
.ikbs-settings_bold:before { content: '\e83b'; } /* '' */
.ikbs-shield:before { content: '\e83c'; } /* '' */
.ikbs-remove:before { content: '\e83d'; } /* '' */
.ikbs-report:before { content: '\e83e'; } /* '' */
.ikbs-right_stop:before { content: '\e83f'; } /* '' */
.ikbs-up:before { content: '\e840'; } /* '' */
.ikbs-cancel_mail:before { content: '\e841'; } /* '' */
.ikbs-wallet:before { content: '\e842'; } /* '' */
.ikbs-withdraw:before { content: '\e843'; } /* '' */
.ikbs-teenyicons_sort-alphabetically-outline:before { content: '\e844'; } /* '' */
.ikbs-template:before { content: '\e845'; } /* '' */
.ikbs-transactions:before { content: '\e846'; } /* '' */
.ikbs-vip:before { content: '\e847'; } /* '' */
.ikbs-user-alert-2:before { content: '\e848'; } /* '' */
.ikbs-download:before { content: '\e849'; } /* '' */
.ikbs-ik:before { content: '\e84a'; } /* '' */
.ikbs-notification:before { content: '\e84b'; } /* '' */
.ikbs-export_csv:before { content: '\e84c'; } /* '' */
.ikbs-vip-1:before { content: '\e84d'; } /* '' */
.ikbs-settings:before { content: '\e84e'; } /* '' */
.ikbs-export_isx:before { content: '\e84f'; } /* '' */
.ikbs-duplicate:before { content: '\e850'; } /* '' */
.ikbs-frame-532:before { content: '\e851'; } /* '' */
.ikbs-frame-533:before { content: '\e852'; } /* '' */
.ikbs-frame-534:before { content: '\e853'; } /* '' */
.ikbs-frame-535:before { content: '\e854'; } /* '' */
.ikbs-frame-536:before { content: '\e855'; } /* '' */
.ikbs-frame-537:before { content: '\e856'; } /* '' */
.ikbs-frame-538:before { content: '\e857'; } /* '' */
.ikbs-frame-551:before { content: '\e858'; } /* '' */
.ikbs-close2:before { content: '\e859'; } /* '' */
.ikbs-frame-61739:before { content: '\e85a'; } /* '' */
.ikbs-frame-61740:before { content: '\e85b'; } /* '' */
.ikbs-frame-61741:before { content: '\e85c'; } /* '' */
.ikbs-planet:before { content: '\e85d'; } /* '' */
.ikbs-frame-61743:before { content: '\e85e'; } /* '' */
.ikbs-frame-61744:before { content: '\e85f'; } /* '' */
.ikbs-frame-61769:before { content: '\e860'; } /* '' */
.ikbs-frame-61737:before { content: '\e861'; } /* '' */
.ikbs-frame-61738:before { content: '\e862'; } /* '' */
.ikbs-frame-61760:before { content: '\e863'; } /* '' */
.ikbs-frame-61761:before { content: '\e864'; } /* '' */
.ikbs-binn:before { content: '\e865'; } /* '' */
.ikbs-card_dup:before { content: '\e866'; } /* '' */
.ikbs-message_history:before { content: '\e867'; } /* '' */
.ikbs-time:before { content: '\e868'; } /* '' */
.ikbs-coffie:before { content: '\e869'; } /* '' */
.ikbs-time_manage:before { content: '\e86a'; } /* '' */
.ikbs-stoplist:before { content: '\e86b'; } /* '' */
.ikbs-vip-2:before { content: '\e86c'; } /* '' */
.ikbs-break:before { content: '\e86d'; } /* '' */
.ikbs-warning:before { content: '\e86f'; } /* '' */
.ikbs-vector:before { content: '\e870'; } /* '' */
.ikbs-trans_arrow:before { content: '\e871'; } /* '' */
.ikbs-day_report:before { content: '\e873'; } /* '' */
.ikbs-blue_cloud:before { content: '\e874'; } /* '' */
.ikbs-frame-61742:before { content: '\e8a1'; } /* '' */
