.ed_Heading1{
    font-size: 36px;
    font-family: "Roboto-regular";
  }
  .ed_Heading2{
    font-size: 24px;
    font-family: "Roboto-regular";
  }
  .ed_Heading3{
    font-size: 18px;
    font-family: "Roboto-regular";
  }
  .ed_Heading4{
    font-size: 14px;
    font-family: "Roboto-regular";
  }
  .ed_Heading5{
    font-size: 12px;
    font-family: "Roboto-regular";
  }
  .ed_Heading6{
    font-size: 10px;
    font-family: "Roboto-regular";
  }
  .ed_Heading_ULTRA{
    font-size: 48px;
    animation: blink 1s infinite;
    display: inline-block;
  }